import React, { useState, useCallback } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import EZShipBoxSideForm from '../components/repairs/EZShipBoxSideForm'
import HaveQuestions from '../components/repairs/HaveQuestions'
import OurExperience from '../components/repairs/OurExperience'
import PropTypes from 'prop-types'
import { processGatsbyImageDataMock } from '../common/gatsbyImageData'

const ClientLetterPage = ({ data }) => {
  const { page } = data
  const [open, setOpen] = useState(false)
  const toggleForm = useCallback(() => setOpen(o => !o), [setOpen])
  const breadcrumbs = [{ url: '/repairs/', name: 'Repairs', }]
  processGatsbyImageDataMock(page.image)
  return (
    <Layout className={'mx-auto'} breadcrumbs={breadcrumbs} canonical={page.url} title={page.title}>
      <Seo title={page.title} canonical={page.url} article={page} seo={page.seo} />
      <h1 className=" text-black-700 my-5 mx-auto w-10/12 max-w-[1200px] text-center text-3xl uppercase">
        {page.title}
      </h1>

      <section className=" relative flex flex-col lg:flex-row max-w-[1366px] mx-auto">
        <div className="w-full order-2 lg:order-1 px-5">
          {page.image ? (
            <GatsbyImage image={getImage(page.image)} alt={page.brand + ' Client Watch Repairs'} />
          ) : null}
          <div
            className="w-full flex flex-col whitespace-pre-wrap spacey-y-2"
            dangerouslySetInnerHTML={{ __html: page.content }}
          />
          <section className="order-1 max-w-[800px] leading-loose">
            <h2 className=" border-grey-700 border-t border-b py-4">
              <a href={page.pdf.url} target="_blank" rel="noreferrer">
                Client Letter {page.title} - PDF
              </a>
            </h2>
          </section>
          <HaveQuestions />
          <OurExperience />
        </div>
        <EZShipBoxSideForm
          open={open}
          toggleForm={toggleForm}
          className={open ? 'lg:custom-bounce' : ' '}
        />
      </section>

      <div
        onClick={toggleForm}
        className={
          open
            ? 'hidden backdrop-blur-sm md:block top-0 left-0 fixed w-screen h-screen z-20 bg-gray-500 opacity-50'
            : 'hidden'
        }
      ></div>
    </Layout>
  )
}
ClientLetterPage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.object,
  }),
}

export const query = graphql`
    query ClientLetterQuery ($pageId: Int!, $next: Int, $previous: Int) {
        page: strapiClientLetter(strapi_id: {eq: $pageId}) {
            url: urlPath
            brand
            title
            content
            pdf {
                url
            }
            image {
                gatsbyImageDataMock
            }
            seo {
                ...SEO
            }
        }
        next: strapiClientLetter(strapi_id: {eq: $next}) {
            title
            brand
            url: urlPath
        }
        previous: strapiClientLetter(strapi_id: {eq: $previous}) {
            title
            brand
            url: urlPath
        }
  }
`

export default ClientLetterPage
